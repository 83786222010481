/* tslint:disable */
/* eslint-disable */
/**
 * Areeb Admin API
 * Areeb Admin REST API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BalanceTransactionDto
 */
export interface BalanceTransactionDto {
    /**
     * 
     * @type {string}
     * @memberof BalanceTransactionDto
     */
    type: BalanceTransactionDtoTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof BalanceTransactionDto
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof BalanceTransactionDto
     */
    balanceType: BalanceTransactionDtoBalanceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BalanceTransactionDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof BalanceTransactionDto
     */
    referenceId?: string;
    /**
     * Timestamp transaction was created at
     * @type {Date}
     * @memberof BalanceTransactionDto
     */
    createdAt?: Date;
}


/**
 * @export
 */
export const BalanceTransactionDtoTypeEnum = {
    Credit: 'CREDIT',
    Debit: 'DEBIT'
} as const;
export type BalanceTransactionDtoTypeEnum = typeof BalanceTransactionDtoTypeEnum[keyof typeof BalanceTransactionDtoTypeEnum];

/**
 * @export
 */
export const BalanceTransactionDtoBalanceTypeEnum = {
    Cash: 'CASH',
    Points: 'POINTS'
} as const;
export type BalanceTransactionDtoBalanceTypeEnum = typeof BalanceTransactionDtoBalanceTypeEnum[keyof typeof BalanceTransactionDtoBalanceTypeEnum];


/**
 * Check if a given object implements the BalanceTransactionDto interface.
 */
export function instanceOfBalanceTransactionDto(value: object): value is BalanceTransactionDto {
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('amount' in value) || value['amount'] === undefined) return false;
    if (!('balanceType' in value) || value['balanceType'] === undefined) return false;
    return true;
}

export function BalanceTransactionDtoFromJSON(json: any): BalanceTransactionDto {
    return BalanceTransactionDtoFromJSONTyped(json, false);
}

export function BalanceTransactionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BalanceTransactionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'],
        'amount': json['amount'],
        'balanceType': json['balanceType'],
        'description': json['description'] == null ? undefined : json['description'],
        'referenceId': json['referenceId'] == null ? undefined : json['referenceId'],
        'createdAt': json['createdAt'] == null ? undefined : (new Date(json['createdAt'])),
    };
}

export function BalanceTransactionDtoToJSON(value?: BalanceTransactionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'amount': value['amount'],
        'balanceType': value['balanceType'],
        'description': value['description'],
        'referenceId': value['referenceId'],
        'createdAt': value['createdAt'] == null ? undefined : ((value['createdAt']).toISOString()),
    };
}

