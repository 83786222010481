/* tslint:disable */
/* eslint-disable */
/**
 * Areeb Admin API
 * Areeb Admin REST API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BalanceTransactionDto } from './BalanceTransactionDto';
import {
    BalanceTransactionDtoFromJSON,
    BalanceTransactionDtoFromJSONTyped,
    BalanceTransactionDtoToJSON,
} from './BalanceTransactionDto';

/**
 * 
 * @export
 * @interface BalanceHistoryResponseDto
 */
export interface BalanceHistoryResponseDto {
    /**
     * 
     * @type {Array<BalanceTransactionDto>}
     * @memberof BalanceHistoryResponseDto
     */
    data: Array<BalanceTransactionDto>;
    /**
     * 
     * @type {number}
     * @memberof BalanceHistoryResponseDto
     */
    totalCount: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceHistoryResponseDto
     */
    totalPages: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceHistoryResponseDto
     */
    currentPage: number;
}

/**
 * Check if a given object implements the BalanceHistoryResponseDto interface.
 */
export function instanceOfBalanceHistoryResponseDto(value: object): value is BalanceHistoryResponseDto {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('totalCount' in value) || value['totalCount'] === undefined) return false;
    if (!('totalPages' in value) || value['totalPages'] === undefined) return false;
    if (!('currentPage' in value) || value['currentPage'] === undefined) return false;
    return true;
}

export function BalanceHistoryResponseDtoFromJSON(json: any): BalanceHistoryResponseDto {
    return BalanceHistoryResponseDtoFromJSONTyped(json, false);
}

export function BalanceHistoryResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BalanceHistoryResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(BalanceTransactionDtoFromJSON)),
        'totalCount': json['totalCount'],
        'totalPages': json['totalPages'],
        'currentPage': json['currentPage'],
    };
}

export function BalanceHistoryResponseDtoToJSON(value?: BalanceHistoryResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ((value['data'] as Array<any>).map(BalanceTransactionDtoToJSON)),
        'totalCount': value['totalCount'],
        'totalPages': value['totalPages'],
        'currentPage': value['currentPage'],
    };
}

