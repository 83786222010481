/* tslint:disable */
/* eslint-disable */
/**
 * Areeb Admin API
 * Areeb Admin REST API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OrderMiniDto } from './OrderMiniDto';
import {
    OrderMiniDtoFromJSON,
    OrderMiniDtoFromJSONTyped,
    OrderMiniDtoToJSON,
} from './OrderMiniDto';

/**
 * 
 * @export
 * @interface OrdersMiniResponseDto
 */
export interface OrdersMiniResponseDto {
    /**
     * 
     * @type {Array<OrderMiniDto>}
     * @memberof OrdersMiniResponseDto
     */
    data: Array<OrderMiniDto>;
    /**
     * 
     * @type {number}
     * @memberof OrdersMiniResponseDto
     */
    queryCount?: number;
    /**
     * 
     * @type {number}
     * @memberof OrdersMiniResponseDto
     */
    totalCount?: number;
    /**
     * 
     * @type {number}
     * @memberof OrdersMiniResponseDto
     */
    totalPages?: number;
}

/**
 * Check if a given object implements the OrdersMiniResponseDto interface.
 */
export function instanceOfOrdersMiniResponseDto(value: object): value is OrdersMiniResponseDto {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function OrdersMiniResponseDtoFromJSON(json: any): OrdersMiniResponseDto {
    return OrdersMiniResponseDtoFromJSONTyped(json, false);
}

export function OrdersMiniResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrdersMiniResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(OrderMiniDtoFromJSON)),
        'queryCount': json['queryCount'] == null ? undefined : json['queryCount'],
        'totalCount': json['totalCount'] == null ? undefined : json['totalCount'],
        'totalPages': json['totalPages'] == null ? undefined : json['totalPages'],
    };
}

export function OrdersMiniResponseDtoToJSON(value?: OrdersMiniResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ((value['data'] as Array<any>).map(OrderMiniDtoToJSON)),
        'queryCount': value['queryCount'],
        'totalCount': value['totalCount'],
        'totalPages': value['totalPages'],
    };
}

