/* tslint:disable */
/* eslint-disable */
/**
 * Areeb Admin API
 * Areeb Admin REST API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdminUpdateUserDto
 */
export interface AdminUpdateUserDto {
    /**
     * User first name
     * @type {string}
     * @memberof AdminUpdateUserDto
     */
    firstName?: string;
    /**
     * User last name
     * @type {string}
     * @memberof AdminUpdateUserDto
     */
    lastName?: string;
    /**
     * User email
     * @type {string}
     * @memberof AdminUpdateUserDto
     */
    email?: string;
    /**
     * User phone number
     * @type {string}
     * @memberof AdminUpdateUserDto
     */
    phoneNumber?: string;
    /**
     * User device id
     * @type {string}
     * @memberof AdminUpdateUserDto
     */
    deviceId?: string;
    /**
     * User locale (language)
     * @type {string}
     * @memberof AdminUpdateUserDto
     */
    locale?: string;
    /**
     * User role
     * @type {number}
     * @memberof AdminUpdateUserDto
     */
    role?: number;
    /**
     * Is user onboarded flag
     * @type {boolean}
     * @memberof AdminUpdateUserDto
     */
    onboarded?: boolean;
    /**
     * Active branch ID
     * @type {number}
     * @memberof AdminUpdateUserDto
     */
    activeBranchId?: number;
    /**
     * Active branch currency
     * @type {string}
     * @memberof AdminUpdateUserDto
     */
    activeBranchCurrency?: AdminUpdateUserDtoActiveBranchCurrencyEnum;
    /**
     * Cart ID
     * @type {number}
     * @memberof AdminUpdateUserDto
     */
    cartId?: number;
    /**
     * Is user verified flag
     * @type {boolean}
     * @memberof AdminUpdateUserDto
     */
    verified?: boolean;
}


/**
 * @export
 */
export const AdminUpdateUserDtoActiveBranchCurrencyEnum = {
    Egp: 'EGP',
    Sar: 'SAR',
    Mad: 'MAD'
} as const;
export type AdminUpdateUserDtoActiveBranchCurrencyEnum = typeof AdminUpdateUserDtoActiveBranchCurrencyEnum[keyof typeof AdminUpdateUserDtoActiveBranchCurrencyEnum];


/**
 * Check if a given object implements the AdminUpdateUserDto interface.
 */
export function instanceOfAdminUpdateUserDto(value: object): value is AdminUpdateUserDto {
    return true;
}

export function AdminUpdateUserDtoFromJSON(json: any): AdminUpdateUserDto {
    return AdminUpdateUserDtoFromJSONTyped(json, false);
}

export function AdminUpdateUserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminUpdateUserDto {
    if (json == null) {
        return json;
    }
    return {
        
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'lastName': json['lastName'] == null ? undefined : json['lastName'],
        'email': json['email'] == null ? undefined : json['email'],
        'phoneNumber': json['phoneNumber'] == null ? undefined : json['phoneNumber'],
        'deviceId': json['deviceId'] == null ? undefined : json['deviceId'],
        'locale': json['locale'] == null ? undefined : json['locale'],
        'role': json['role'] == null ? undefined : json['role'],
        'onboarded': json['onboarded'] == null ? undefined : json['onboarded'],
        'activeBranchId': json['activeBranchId'] == null ? undefined : json['activeBranchId'],
        'activeBranchCurrency': json['activeBranchCurrency'] == null ? undefined : json['activeBranchCurrency'],
        'cartId': json['cartId'] == null ? undefined : json['cartId'],
        'verified': json['verified'] == null ? undefined : json['verified'],
    };
}

export function AdminUpdateUserDtoToJSON(value?: AdminUpdateUserDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'email': value['email'],
        'phoneNumber': value['phoneNumber'],
        'deviceId': value['deviceId'],
        'locale': value['locale'],
        'role': value['role'],
        'onboarded': value['onboarded'],
        'activeBranchId': value['activeBranchId'],
        'activeBranchCurrency': value['activeBranchCurrency'],
        'cartId': value['cartId'],
        'verified': value['verified'],
    };
}

