/* tslint:disable */
/* eslint-disable */
/**
 * Areeb Admin API
 * Areeb Admin REST API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  OrderDetailsDto,
  OrderDto,
  OrdersMiniResponseDto,
  OrdersResponseDto,
  UpdateOrderDto,
} from '../models/index';
import {
    OrderDetailsDtoFromJSON,
    OrderDetailsDtoToJSON,
    OrderDtoFromJSON,
    OrderDtoToJSON,
    OrdersMiniResponseDtoFromJSON,
    OrdersMiniResponseDtoToJSON,
    OrdersResponseDtoFromJSON,
    OrdersResponseDtoToJSON,
    UpdateOrderDtoFromJSON,
    UpdateOrderDtoToJSON,
} from '../models/index';

export interface GetOrderByIdRequest {
    id: string;
}

export interface GetOrderByOrderNumberRequest {
    orderNumber: string;
}

export interface GetOrdersRequest {
    page: number;
    query?: string;
    startDate?: string;
    endDate?: string;
    branchId?: number;
    status?: GetOrdersStatusEnum;
    pageSize?: number;
    orderBy?: Array<string>;
}

export interface GetOrdersByUserIdRequest {
    userId: string;
    page: number;
    pageSize?: number;
    status?: GetOrdersByUserIdStatusEnum;
    branchId?: number;
}

export interface UpdateOrderRequest {
    id: string;
    updateOrderDto: UpdateOrderDto;
}

/**
 * 
 */
export class OrderServiceApi extends runtime.BaseAPI {

    /**
     * Fetches order with ID.
     */
    async getOrderByIdRaw(requestParameters: GetOrderByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderDetailsDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getOrderById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/order/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderDetailsDtoFromJSON(jsonValue));
    }

    /**
     * Fetches order with ID.
     */
    async getOrderById(requestParameters: GetOrderByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderDetailsDto> {
        const response = await this.getOrderByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetches order with order number.
     */
    async getOrderByOrderNumberRaw(requestParameters: GetOrderByOrderNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderDto>> {
        if (requestParameters['orderNumber'] == null) {
            throw new runtime.RequiredError(
                'orderNumber',
                'Required parameter "orderNumber" was null or undefined when calling getOrderByOrderNumber().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/order/orderNumber/{orderNumber}`.replace(`{${"orderNumber"}}`, encodeURIComponent(String(requestParameters['orderNumber']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderDtoFromJSON(jsonValue));
    }

    /**
     * Fetches order with order number.
     */
    async getOrderByOrderNumber(requestParameters: GetOrderByOrderNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderDto> {
        const response = await this.getOrderByOrderNumberRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetches all orders, and optionally filtered by branch and/or order status.
     */
    async getOrdersRaw(requestParameters: GetOrdersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrdersResponseDto>> {
        if (requestParameters['page'] == null) {
            throw new runtime.RequiredError(
                'page',
                'Required parameter "page" was null or undefined when calling getOrders().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['query'] != null) {
            queryParameters['query'] = requestParameters['query'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['branchId'] != null) {
            queryParameters['branchId'] = requestParameters['branchId'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['pageSize'] = requestParameters['pageSize'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/order`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrdersResponseDtoFromJSON(jsonValue));
    }

    /**
     * Fetches all orders, and optionally filtered by branch and/or order status.
     */
    async getOrders(requestParameters: GetOrdersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrdersResponseDto> {
        const response = await this.getOrdersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetches all orders for a specific user.
     */
    async getOrdersByUserIdRaw(requestParameters: GetOrdersByUserIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrdersMiniResponseDto>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling getOrdersByUserId().'
            );
        }

        if (requestParameters['page'] == null) {
            throw new runtime.RequiredError(
                'page',
                'Required parameter "page" was null or undefined when calling getOrdersByUserId().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['pageSize'] = requestParameters['pageSize'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        if (requestParameters['branchId'] != null) {
            queryParameters['branchId'] = requestParameters['branchId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/order/user/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrdersMiniResponseDtoFromJSON(jsonValue));
    }

    /**
     * Fetches all orders for a specific user.
     */
    async getOrdersByUserId(requestParameters: GetOrdersByUserIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrdersMiniResponseDto> {
        const response = await this.getOrdersByUserIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates order.
     */
    async updateOrderRaw(requestParameters: UpdateOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateOrder().'
            );
        }

        if (requestParameters['updateOrderDto'] == null) {
            throw new runtime.RequiredError(
                'updateOrderDto',
                'Required parameter "updateOrderDto" was null or undefined when calling updateOrder().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/order/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateOrderDtoToJSON(requestParameters['updateOrderDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderDtoFromJSON(jsonValue));
    }

    /**
     * Updates order.
     */
    async updateOrder(requestParameters: UpdateOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderDto> {
        const response = await this.updateOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetOrdersStatusEnum = {
    New: 'NEW',
    Pending: 'PENDING',
    Processing: 'PROCESSING',
    Shipped: 'SHIPPED',
    Delivered: 'DELIVERED',
    Canceled: 'CANCELED',
    Returned: 'RETURNED'
} as const;
export type GetOrdersStatusEnum = typeof GetOrdersStatusEnum[keyof typeof GetOrdersStatusEnum];
/**
 * @export
 */
export const GetOrdersByUserIdStatusEnum = {
    New: 'NEW',
    Pending: 'PENDING',
    Processing: 'PROCESSING',
    Shipped: 'SHIPPED',
    Delivered: 'DELIVERED',
    Canceled: 'CANCELED',
    Returned: 'RETURNED'
} as const;
export type GetOrdersByUserIdStatusEnum = typeof GetOrdersByUserIdStatusEnum[keyof typeof GetOrdersByUserIdStatusEnum];
