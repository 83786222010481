/* tslint:disable */
/* eslint-disable */
/**
 * Areeb Admin API
 * Areeb Admin REST API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderMiniDto
 */
export interface OrderMiniDto {
    /**
     * Order ID
     * @type {number}
     * @memberof OrderMiniDto
     */
    id: number;
    /**
     * Order number
     * @type {string}
     * @memberof OrderMiniDto
     */
    orderNumber: string;
    /**
     * Order status
     * @type {number}
     * @memberof OrderMiniDto
     */
    status: number;
    /**
     * Formatted address returned from google maps reverse geocoding of latitude and longitude
     * @type {string}
     * @memberof OrderMiniDto
     */
    formattedAddress: string | null;
    /**
     * Location phone number
     * @type {string}
     * @memberof OrderMiniDto
     */
    phoneNumber: string;
    /**
     * Total price of order items
     * @type {number}
     * @memberof OrderMiniDto
     */
    totalPrice: number;
    /**
     * User ID
     * @type {string}
     * @memberof OrderMiniDto
     */
    userId: string;
    /**
     * Timestamp order was created at
     * @type {Date}
     * @memberof OrderMiniDto
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the OrderMiniDto interface.
 */
export function instanceOfOrderMiniDto(value: object): value is OrderMiniDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('orderNumber' in value) || value['orderNumber'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('formattedAddress' in value) || value['formattedAddress'] === undefined) return false;
    if (!('phoneNumber' in value) || value['phoneNumber'] === undefined) return false;
    if (!('totalPrice' in value) || value['totalPrice'] === undefined) return false;
    if (!('userId' in value) || value['userId'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    return true;
}

export function OrderMiniDtoFromJSON(json: any): OrderMiniDto {
    return OrderMiniDtoFromJSONTyped(json, false);
}

export function OrderMiniDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderMiniDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'orderNumber': json['orderNumber'],
        'status': json['status'],
        'formattedAddress': json['formattedAddress'],
        'phoneNumber': json['phoneNumber'],
        'totalPrice': json['totalPrice'],
        'userId': json['userId'],
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function OrderMiniDtoToJSON(value?: OrderMiniDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'orderNumber': value['orderNumber'],
        'status': value['status'],
        'formattedAddress': value['formattedAddress'],
        'phoneNumber': value['phoneNumber'],
        'totalPrice': value['totalPrice'],
        'userId': value['userId'],
        'createdAt': ((value['createdAt']).toISOString()),
    };
}

