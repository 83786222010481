/* tslint:disable */
/* eslint-disable */
/**
 * Areeb Admin API
 * Areeb Admin REST API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AdminUpdateUserDto,
  BalanceDto,
  BalanceHistoryResponseDto,
  BalanceTransactionDto,
  CreateUserDto,
  UserDto,
  UsersResponseDto,
} from '../models/index';
import {
    AdminUpdateUserDtoFromJSON,
    AdminUpdateUserDtoToJSON,
    BalanceDtoFromJSON,
    BalanceDtoToJSON,
    BalanceHistoryResponseDtoFromJSON,
    BalanceHistoryResponseDtoToJSON,
    BalanceTransactionDtoFromJSON,
    BalanceTransactionDtoToJSON,
    CreateUserDtoFromJSON,
    CreateUserDtoToJSON,
    UserDtoFromJSON,
    UserDtoToJSON,
    UsersResponseDtoFromJSON,
    UsersResponseDtoToJSON,
} from '../models/index';

export interface CreateUserRequest {
    createUserDto: CreateUserDto;
}

export interface DeleteUserByIdRequest {
    userId: string;
}

export interface GetUserBalanceHistoryRequest {
    userId: string;
    balanceType?: GetUserBalanceHistoryBalanceTypeEnum;
    page?: number;
    pageSize?: number;
}

export interface GetUserByIdRequest {
    userId: string;
}

export interface GetUserByPhoneNumberRequest {
    phoneNumber: string;
}

export interface GetUsersRequest {
    page: number;
    query?: string;
    startDate?: string;
    endDate?: string;
    verified?: boolean;
    pageSize?: number;
    orderBy?: Array<string>;
}

export interface ProcessUserBalanceRequest {
    userId: string;
    balanceTransactionDto: BalanceTransactionDto;
}

export interface UpdateUserRequest {
    userId: string;
    adminUpdateUserDto: AdminUpdateUserDto;
}

/**
 * 
 */
export class UserServiceApi extends runtime.BaseAPI {

    /**
     * Creates user in database
     */
    async createUserRaw(requestParameters: CreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDto>> {
        if (requestParameters['createUserDto'] == null) {
            throw new runtime.RequiredError(
                'createUserDto',
                'Required parameter "createUserDto" was null or undefined when calling createUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUserDtoToJSON(requestParameters['createUserDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDtoFromJSON(jsonValue));
    }

    /**
     * Creates user in database
     */
    async createUser(requestParameters: CreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDto> {
        const response = await this.createUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes user by ID
     */
    async deleteUserByIdRaw(requestParameters: DeleteUserByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDto>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling deleteUserById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/user/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDtoFromJSON(jsonValue));
    }

    /**
     * Deletes user by ID
     */
    async deleteUserById(requestParameters: DeleteUserByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDto> {
        const response = await this.deleteUserByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get user balance transaction history
     */
    async getUserBalanceHistoryRaw(requestParameters: GetUserBalanceHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BalanceHistoryResponseDto>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling getUserBalanceHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['balanceType'] != null) {
            queryParameters['balanceType'] = requestParameters['balanceType'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['pageSize'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/user/{userId}/balance/history`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BalanceHistoryResponseDtoFromJSON(jsonValue));
    }

    /**
     * Get user balance transaction history
     */
    async getUserBalanceHistory(requestParameters: GetUserBalanceHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BalanceHistoryResponseDto> {
        const response = await this.getUserBalanceHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets full user by ID
     */
    async getUserByIdRaw(requestParameters: GetUserByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDto>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling getUserById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/user/full/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDtoFromJSON(jsonValue));
    }

    /**
     * Gets full user by ID
     */
    async getUserById(requestParameters: GetUserByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDto> {
        const response = await this.getUserByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets full user by phone number
     */
    async getUserByPhoneNumberRaw(requestParameters: GetUserByPhoneNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDto>> {
        if (requestParameters['phoneNumber'] == null) {
            throw new runtime.RequiredError(
                'phoneNumber',
                'Required parameter "phoneNumber" was null or undefined when calling getUserByPhoneNumber().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/user/full/phone/{phoneNumber}`.replace(`{${"phoneNumber"}}`, encodeURIComponent(String(requestParameters['phoneNumber']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDtoFromJSON(jsonValue));
    }

    /**
     * Gets full user by phone number
     */
    async getUserByPhoneNumber(requestParameters: GetUserByPhoneNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDto> {
        const response = await this.getUserByPhoneNumberRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetches all users, and optionally filtered by status.
     */
    async getUsersRaw(requestParameters: GetUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UsersResponseDto>> {
        if (requestParameters['page'] == null) {
            throw new runtime.RequiredError(
                'page',
                'Required parameter "page" was null or undefined when calling getUsers().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['query'] != null) {
            queryParameters['query'] = requestParameters['query'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['verified'] != null) {
            queryParameters['verified'] = requestParameters['verified'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['pageSize'] = requestParameters['pageSize'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UsersResponseDtoFromJSON(jsonValue));
    }

    /**
     * Fetches all users, and optionally filtered by status.
     */
    async getUsers(requestParameters: GetUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UsersResponseDto> {
        const response = await this.getUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Process balance transaction (credit/debit) for a user
     */
    async processUserBalanceRaw(requestParameters: ProcessUserBalanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BalanceDto>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling processUserBalance().'
            );
        }

        if (requestParameters['balanceTransactionDto'] == null) {
            throw new runtime.RequiredError(
                'balanceTransactionDto',
                'Required parameter "balanceTransactionDto" was null or undefined when calling processUserBalance().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/user/{userId}/balance/transaction`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BalanceTransactionDtoToJSON(requestParameters['balanceTransactionDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BalanceDtoFromJSON(jsonValue));
    }

    /**
     * Process balance transaction (credit/debit) for a user
     */
    async processUserBalance(requestParameters: ProcessUserBalanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BalanceDto | null | undefined > {
        const response = await this.processUserBalanceRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Updates user in DB
     */
    async updateUserRaw(requestParameters: UpdateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDto>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling updateUser().'
            );
        }

        if (requestParameters['adminUpdateUserDto'] == null) {
            throw new runtime.RequiredError(
                'adminUpdateUserDto',
                'Required parameter "adminUpdateUserDto" was null or undefined when calling updateUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/user/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: AdminUpdateUserDtoToJSON(requestParameters['adminUpdateUserDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDtoFromJSON(jsonValue));
    }

    /**
     * Updates user in DB
     */
    async updateUser(requestParameters: UpdateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDto> {
        const response = await this.updateUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetUserBalanceHistoryBalanceTypeEnum = {
    Cash: 'CASH',
    Points: 'POINTS'
} as const;
export type GetUserBalanceHistoryBalanceTypeEnum = typeof GetUserBalanceHistoryBalanceTypeEnum[keyof typeof GetUserBalanceHistoryBalanceTypeEnum];
